import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Typography,
    makeStyles,
} from "@material-ui/core";

//--- Material Icon
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import DeleteIcon from "@material-ui/icons/Delete";

import { TreeItem } from '@material-ui/lab';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import * as documentStore from '../../redux/store/document/document-management.store';
import * as appActions from "../../core/app.store";
import "./document-management.view.scss";
import {
    NotificationMessageType
} from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";
import ShowNotification from "../../components/react-notifications/react-notifications";

import FileManagement from "../../components/file_management/file_management";
import * as planning_categoryAction from "../../redux/store/planning-category-management/planning-category.store";
import * as governmentAction from "../../redux/store/government-management/government.store";

function DocumentManagementView(props) {
    const [files, setFiles] = useState([]);
    const [category, setCategory] = useState([]);
    const [government, setGovernment] = useState([]);

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        getLookupPlanningCategory();
        getLookupGovernment();
    }
    const getLookupPlanningCategory = () => {
        return new Promise((resolve, reject) => {
            planning_categoryAction.GetLookup().then(
                (res) => {
                    if (res && res.content)
                        setCategory(res.content);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupGovernment = () => {
        return new Promise((resolve, reject) => {
            governmentAction.GetLookup().then(
                (res) => {
                    if (res && res.content)
                        setGovernment(res.content);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    return (
        <FileManagement
            files={files}
            setFiles={setFiles}
            isShowUi={1}
            acceptedFiles={["jpeg", "png", "jpg", "gif", "xls", "xlsx", "doc","docx", "ppt","pps","pptx", "pdf"]}
            category={category}
            government={government}
        />
        );
}

const mapStateToProps = state => ({
    //InitDocument: state.InitDocument,
    //listBreadCrumbs: state.InitDocument.listBreadCrumbs,
    //listAllDocument: state.InitDocument.listAllDocument,
    //listAllFolder: state.InitDocument.listAllFolder,
    //listAllFile: state.InitDocument.listAllFile,
    //listFolderIds: state.InitDocument.listFolderIds,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    //SaveCurrentData: documentStore.SaveCurrentData,
    //RemoveDocument: documentStore.RemoveDocument,
    //GetAllFolder: documentStore.GetAllFolder,
    //GetAllFileByFolder: (parentId, pageIndex, pageSize) => documentStore.GetAllFileByFolder(parentId, pageIndex, pageSize),
    //GetAllFileAndFolder: (parentId) => documentStore.GetAllFileAndFolder(parentId),
    //GetLookupDistrict: documentStore.GetLookupDistrict,
    //GetLookupCommune: documentStore.GetLookupCommune,
    showLoading: appActions.ShowLoading,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DocumentManagementView)